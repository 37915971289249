import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Role } from '../../models/role.models';
import { AuthService } from '../../services/auth.service';
import * as $ from 'jquery';
import { TokenService } from 'src/app/services/token.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit{
  @ViewChild('failSwal') failSwal: SwalComponent
  Role = Role;
  loginForm: UntypedFormGroup;
  loading = false;
  valid:boolean = false;
  anioActual: number;
 
  constructor(private router: Router,
     private authService: AuthService,
     private formBuilder:UntypedFormBuilder,
     private _tokenService:TokenService) { 
      const fecha = new Date();
      this.anioActual = fecha.getFullYear();
     }

  ngOnInit(): void {
    this._tokenService.logOut()
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
  })

  if(this.authService.isAuthorized())this.router.navigate(['movimientos'])

  }

  onSubmit(formData: {email: string, password: string}) {
    if(this.loginForm.invalid){
      return;
    }
      this.loading = true;
      // this.valid = true;

    // $("#btn-login").toggleClass('disabled');
    this.authService.login(formData.email, formData.password)
    .then(response => {
      if(response){
        localStorage.setItem('userReporte', JSON.stringify(formData.email));
        this.router.navigate(['dashboard/movimientos']);
        this.loading = false;
      }else{
        $("#btn-login").removeClass('disabled');
        this.loading = false;
        this.failSwal.fire();
      }
    })
    .catch(error => {
      $("#btn-login").removeClass('disabled');
      this.loading = false;
      this.failSwal.fire().finally(()=>{
         //Logout
        this._tokenService.logOut()
      });

    });
  }

}
